@import 'styles/variables.scss';

body{
    background-color: $background-color;
    color: $primary-color;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

