.spawn-effect {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;

    span {
        display: inline-block;
        opacity: 0;
        animation: spawn 0.5s forwards;
    }

    //  a better way to do this is to use SCSS
    @for $i from 1 through 100 {
        span:nth-child(#{$i}) {
            animation-delay: 0.05s * $i;
        }
    }

    @keyframes spawn {
        from {
            opacity: 0;
            transform: translateY(20px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.spawn-effect-reverse {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;

    span {
        display: inline-block;
        opacity: 0;
        animation: spawn 0.5s forwards;
    }

    //  a better way to do this is to use SCSS
    @for $i from 1 through 500 {
        span:nth-child(#{500-$i}) {
            animation-delay: 0.05s * $i;
        }
    }

    @keyframes spawn {
        from {
            opacity: 0;
            transform: translateY(20px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
}