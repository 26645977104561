@import '../../styles/variables.scss';

footer{
    background-color: $primary-color;
    color: $background-color;
    text-align: center;
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
    display: inline-block;
    justify-content: center;

    a{
        color: $background-color;
        text-decoration: none;
    }
    
    .line{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.5rem 0;

        >*{
            margin: auto 0.5rem;
        }
    }

    .small{
        font-size: 0.8rem;
        color: $secondary-color;

        a{
            color: $secondary-color;
            text-decoration: underline;
        }
    }
}
